import React from "react";
import Footer from "../Footer";
import Header from "../Header";

export const TermsAndConditionsUandI = () => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    textAlign: "left",
    marginBottom: "200px",
  };

  const titleStyle = {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
    textAlign: "center",
  };

  const sectionTitleStyle = {
    fontSize: "22px",
    fontWeight: "bold",
    margin: "20px 0 10px",
    color: "#444",
  };

  const paragraphStyle = {
    fontSize: "16px",
    lineHeight: "1.6",
    color: "#555",
    textAlign: "justify",
    marginBottom: "10px",
  };

  return (
    <>
      <Header />
      <div style={containerStyle}>
        <h2 style={titleStyle}>Terms of Use and End User License Agreement</h2>
        <p style={paragraphStyle}>Last updated: December 2024</p>

        <h3 style={sectionTitleStyle}>I. Introduction</h3>
        <p style={paragraphStyle}>
          This Terms of Use and End User License Agreement (collectively, the
          “Agreement”) constitutes a legally binding agreement between you and
          I&U Smart Tech LLC (“we,” “us,” or “our”) regarding your access to and
          use of the "U & I" software application for mobile devices (the
          “App”).
        </p>

        <h3 style={sectionTitleStyle}>II. Acknowledgement</h3>
        <p style={paragraphStyle}>
          This Agreement is concluded between you and I&U Smart Tech LLC only,
          not with Apple. We, not Apple, are solely responsible for the App and
          its content. Your use of the App must comply with the App Store Terms
          of Service.
        </p>

        <h3 style={sectionTitleStyle}>III. Scope of License</h3>
        <p style={paragraphStyle}>
          We grant you a non-transferable license to use the App on any
          Apple-branded products you own or control, as permitted by the Usage
          Rules set forth in the App Store Terms of Service. You may not copy,
          reverse-engineer, disassemble, attempt to derive the source code of,
          modify, or create derivative works of the App, any updates, or any
          part thereof, except as expressly permitted by applicable law. If you
          sell your Apple Device, you must remove the App from it before doing
          so.
        </p>

        <h3 style={sectionTitleStyle}>IV. Consent to Use of Data</h3>
        <p style={paragraphStyle}>
          The App may collect and use technical data and related information,
          including information about your device, system, application software,
          and peripherals, to facilitate updates, support, and improve services.
          This data will be anonymized and not personally identifiable.
        </p>

        <h3 style={sectionTitleStyle}>V. Termination</h3>
        <p style={paragraphStyle}>
          This Agreement is effective until terminated by you or us. Your rights
          under this Agreement will terminate automatically if you fail to
          comply with its terms.
        </p>

        <h3 style={sectionTitleStyle}>VI. External Services</h3>
        <p style={paragraphStyle}>
          The App may provide access to third-party services and websites
          (“External Services”). Your use of these External Services is at your
          own risk, and we disclaim responsibility for their content, accuracy,
          or availability. You agree not to use External Services to infringe on
          intellectual property rights, harass, abuse, or violate any applicable
          laws.
        </p>

        <h3 style={sectionTitleStyle}>VII. No Warranty</h3>
        <p style={paragraphStyle}>
          The App and its services are provided "as is" and “as available” with
          all faults and without warranty of any kind. To the maximum extent
          permitted by applicable law, we disclaim all warranties, including,
          but not limited to, implied warranties of merchantability and fitness
          for a particular purpose.
        </p>

        <h3 style={sectionTitleStyle}>VIII. Limitation of Liability</h3>
        <p style={paragraphStyle}>
          To the extent not prohibited by law, we will not be liable for
          incidental, special, or consequential damages, including but not
          limited to loss of data, business interruptions, or damages exceeding
          fifty dollars ($50.00).
        </p>

        <h3 style={sectionTitleStyle}>IX. Export Compliance</h3>
        <p style={paragraphStyle}>
          You may not use or export the App except as authorized by U.S. law and
          the laws of your jurisdiction. The App may not be exported to U.S.-
          embargoed countries or persons on prohibited lists.
        </p>

        <h3 style={sectionTitleStyle}>X. Government End Users</h3>
        <p style={paragraphStyle}>
          The App is a “Commercial Item” under applicable federal regulations
          and is licensed to U.S. Government users with the same rights granted
          to other users under this Agreement.
        </p>

        <h3 style={sectionTitleStyle}>XI. Governing Law</h3>
        <p style={paragraphStyle}>
          This Agreement is governed by the laws of the State of California,
          USA, except where local laws apply. Any disputes will be resolved in
          the courts of Santa Clara, California.
        </p>

        <h3 style={sectionTitleStyle}>XII. Privacy Policy</h3>
        <p style={paragraphStyle}>
          Please refer to our Privacy Policy to understand how we collect, use,
          and protect your data.
        </p>

        <h3 style={sectionTitleStyle}>XIII. Contact Information</h3>
        <p style={paragraphStyle}>
          If you have any questions, please contact us at:
          <br />
          I&U Smart Tech LLC
          <br />
           Fessler Ave
          <br />
          Naperville, IL 60565
          <br />
          Email: support@iusmarttech.com
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditionsUandI;
