import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// huquqshunos ai docs

import { PrivacyPolicyHuquqshunosAI } from "./huquqshunosai/PrivacyPolicyHuquqshunosAI";
import { TermsAndConditionsHuquqshunosAI } from "./huquqshunosai/TermsAndConditionsHuquqshunosAI";
import { PrivacyPolicyMobileHuquqshunosAI } from "./huquqshunosai/PrivacyPolicyMobileHuquqshunosAI";
import { TermsAndConditionsMobileHuquqshunosAI } from "./huquqshunosai/TermsAndConditionsMobileHuquqshunosAI";


// millat ai docs

import { PrivacyPolicyMillatAI } from "./millatai/PrivacyPolicyMillatAI";
import { TermsAndConditionsMillatAI } from "./millatai/TermsAndConditionsMillatAI";
import { PrivacyPolicyMobileMillatAI } from "./millatai/PrivacyPolicyMobileMillatAI";
import { TermsAndConditionsMobileMillatAI } from "./millatai/TermsAndConditionsMobileMillatAI";


// u and i docs
import { PrivacyPolicyUandI } from "./uandi//PrivacyPolicyUandI";
import { TermsAndConditionsUandI } from "./uandi//TermsAndConditionsUandI";
import { PrivacyPolicyMobileUandI } from "./uandi//PrivacyPolicyMobileUandI";
import { TermsAndConditionsMobileUandI } from "./uandi/TermsAndConditionsMobileUandI";

// inaaya ai docs
import { PrivacyPolicy } from "./Inaaya AI/PrivacyPolicy";
import { TermsAndConditions } from "./Inaaya AI/TermsAndConditions";
import { PrivacyPolicyMobile } from "./Inaaya AI/PrivacyPolicyMobile";
import { TermsAndConditionsMobile } from "./Inaaya AI/TermsAndConditionsMobile";
import { LandingPage } from "./Landing";


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/mobile/privacy-policy" element={<PrivacyPolicyMobile />} />
        <Route path="/mobile/terms-and-conditions" element={<TermsAndConditionsMobile />} />
        <Route path="/uandi/privacy-policy" element={<PrivacyPolicyUandI />} />
        <Route path="/uandi/terms-and-conditions" element={<TermsAndConditionsUandI />} />
        <Route path="/uandi/mobile/privacy-policy" element={<PrivacyPolicyMobileUandI />} />
        <Route path="/uandi/mobile/terms-and-conditions" element={<TermsAndConditionsMobileUandI />} />
        <Route path="/millatai/privacy-policy" element={<PrivacyPolicyMillatAI />} />
        <Route path="/millatai/terms-and-conditions" element={<TermsAndConditionsMillatAI />} />
        <Route path="/millatai/mobile/privacy-policy" element={<PrivacyPolicyMobileMillatAI />} />
        <Route path="/millatai/mobile/terms-and-conditions" element={<TermsAndConditionsMobileMillatAI />} />
        <Route path="/huquqshunosai/privacy-policy" element={<PrivacyPolicyHuquqshunosAI />} />
        <Route path="/huquqshunosai/terms-and-conditions" element={<TermsAndConditionsHuquqshunosAI />} />
        <Route path="/huquqshunosai/mobile/privacy-policy" element={<PrivacyPolicyMobileHuquqshunosAI />} />
        <Route path="/huquqshunosai/mobile/terms-and-conditions" element={<TermsAndConditionsMobileHuquqshunosAI />} />
      </Routes>
    </Router>
  );
};

export default App;
